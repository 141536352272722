<script lang="jsx">
import EstimatedTag from 'public/src/pages/goods_detail/components/top/innerComponents/Prices/components/EstimatedTag/index'
import { souceFormat } from 'public/src/pages/components/coupon/mall/utils'
import schttp from 'public/src/services/schttp'
import AddOnItem from 'public/src/pages/common/add-on/index.js'
import { markPoint } from 'public/src/services/mark/index'
const { host, langPath } = gbCommonInfo || {}

export default {
  name: 'EstimatedNoThresholdNewWarper',
  components: {
    EstimatedTag
  },
  props: {
    getEstimatedInfo: {
      type: Object,
      default: () => ({})
    },
    promotionInfo: {
      type: Array,
      default: () => []
    },
    language: {
      type: Object,
      default: () => ({})
    },
    estimatedInfo: {
      type: Object,
      default: () => ({})
    },
    detail: {
      type: Object,
      default: () => ({})
    },
    isQuickAdd: {
      type: Boolean,
      default: false
    },
    fsAbt: {
      type: Object,
      default: () => ({})
    },
    hasLink: {
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    IS_USE_NEW_ADD_MORE: '',
    toCartsGoods: []
  }),
  computed: {
    optimalCoupon(){
      let couponList = this.getEstimatedInfo?.coupons
      let bestCoupon = couponList?.find(item=>{
        return item.coupon_code === this.getEstimatedInfo?.optimalCoupon?.coupon_code
      })
      return bestCoupon ? {
        ...this.getEstimatedInfo?.optimalCoupon,
        ...bestCoupon,
        rule: this.getEstimatedInfo?.optimalCoupon?.rule
      } : {}
    },
    isShowAddMore() {
      const E_Price_AddOnItem = this.getEstimatedInfo?.EstimatedPrice?.p?.E_Price_AddOnItem
      const {
        apply_for,
        rule_dimension,
        satisfied,
        coupon_dimension
      } = this.optimalCoupon || {}
      const isRightRange = coupon_dimension == 1 && 
        (
          ( apply_for == 1 && rule_dimension == 5) || 
          ((apply_for == 2 || apply_for == 3) && (rule_dimension == 2 || rule_dimension == 5))
        )
      return E_Price_AddOnItem === 'AddOnItem'
        && satisfied == 0
        && (this.IS_USE_NEW_ADD_MORE || isRightRange)
    }
  },
  async mounted() {
    let res = await schttp({
      method: 'POST',
      url: '/api/config/apolloConfig/query',
      data: { apolloKeys: 'IS_USE_NEW_ADD_MORE' }
    })
    const { IS_USE_NEW_ADD_MORE } = res?.info || {}
    this.IS_USE_NEW_ADD_MORE = IS_USE_NEW_ADD_MORE
  },
  methods: {
    async addMoreHandle() {
      if (!this.isShowAddMore) return
      this.$emit('handleAdd')
      await this.$nextTick()
      const {
        apply_for, // 适用商品范围 1全场;2部分产品;3部分分类;7选品;8部分店铺
        category_name = [],
        coupon,
        coupon_type_id,
        id,
        coupon_dimension // 发放主体：1是平台券 2是mall券 3是店铺券
      } = souceFormat(this.optimalCoupon) || {}
      const { Estimatedroad } = this.getEstimatedInfo || {}
      const isToPage = coupon_dimension == 1 && (apply_for == 2 || apply_for == 3) && (coupon_type_id == 2 || coupon_type_id == 5)
      const isToDrawer = this.IS_USE_NEW_ADD_MORE || (coupon_dimension == 1 && apply_for == 1 && coupon_type_id == 5)
      if(isToDrawer){ // 平台券-券适用范围为全部商品的应付价折扣的平台券
        // 唤起凑单弹窗 
        let { goods_id, cat_id } = this.detail
        this.toCartsGoods = [] // 恢复默认
        const isTopGoods = this.fsAbt?.Gatheringorders?.p?.Gatheringorders === 'Topping'
        const isOpenRecommend = this.fsAbt?.EstimatedNothreShowType?.p?.recommendtype == '1'
        const queryInfo = {
          addOnType: 2, // 问产品拿
          sceneId: 152, // 推荐场景id
          cateIds: [cat_id]

        }
        if(isTopGoods) {
          // 置顶主skc
          queryInfo.adp = [goods_id]
        }else{
          queryInfo.goodsIds = [goods_id]
        }
        if(isOpenRecommend) {
          // 基于商详商品推出凑单商品
          queryInfo.mainGoodsIds = [goods_id]
        }
        AddOnItem.open({
          props: {
            type: 'coupon',
            coupon: this.optimalCoupon?.coupon_code, // **券码** 必传！！！
            queryInfo,
            saInfo: { // 主要用于埋点
              activity_from: 'goods_detail_coupon_add',
              state: 'goods_detail_coupon_add',
            },
            config: {
              isClickToDetail: true,
            },
          },
          on: {
            close: () => {
              const hasMainGood = this.toCartsGoods.find(item => item == goods_id)
              if(Estimatedroad === 'Recommend' && hasMainGood) {
                this.$emit('onScrollRecommend')
              }
              if (!this.isQuickAdd) return
              appEventCenter.$emit('quickAddCloseAddOn')
            },
            updateCart: (cartInfo) => {
              const curGoodsId = cartInfo?.carts?.[0]?.product?.goods_id
              if(curGoodsId) this.toCartsGoods.push(curGoodsId)
            }
          }
        })
      }else if(isToPage){ // 平台券-原价折扣券/应付折扣券 -部分商品券 || 平台券-原价折扣券/应付折扣券-部分品类券
        // 跳转凑单页
        const category_id = category_name.map(item => item.cat_id)
        const coupon_type = 1
        const catIds = (category_id && category_id.join(',')) || ''
        const showTip = coupon_type_id == '2' ? 1 : 0
        let query = ''
        switch (apply_for) {
          case '2':
            // 跳转可用商品弹窗, 去页面内请求数据
            query = `code=${coupon}&typeId=${coupon_type_id}`
            markPoint('toNextPageClick', 'public')
            location.href = `${host}${langPath}/user/coupon/add-items?${query}`
            break
          case '3':
            // 跳转对应品类列表页，需要携带品类id category_id，打开新窗口
            // 如果coupon_type_id=2携带参数ShowTip=1&CouponCode={券码}
            query = `ShowTip=${showTip}&CouponCode=${coupon.toLowerCase()}&multi_cat_ids=${catIds}&act_id=${id}&coupon_type=${coupon_type}`
            sessionStorage.removeItem('closeCouponTip')
            markPoint('toNextPageClick', 'public')
            location.href = `${host}${langPath}/coupon-product.html?${query}`
            break
          default:
        }
      }
    },
  },
  render() {
    const {
      language,
      getEstimatedInfo,
      promotionInfo,
      isShowAddMore,
      hasLink
    } = this
    return <EstimatedTag
      is-estimated-no-threshold-new-type={true} 
      analysis-data={{ location: 'page' }}
      language={language}        
      estimated-info={getEstimatedInfo}
      promotion-info={promotionInfo}
      is-show-add-more={isShowAddMore}
      has-link={hasLink}
      vOn:clickLink={this.addMoreHandle}
    />
  }
}

</script>
